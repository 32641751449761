<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">RMenuTab</h2>
      <r-menu-tab :level="2"></r-menu-tab>
    </template>
    <template #page-content>
      <div></div>
    </template>
  </r-page>
</template>
<script>
export default {}
</script>
